<template>
  <el-row v-loading="isLoading">
    <div style="position: fixed;width: 395px;height: 100%;" :style="cardMarginTop">
      <div class="grid-content bg-purple">
        <div
          class="pic member_card"
          :style="{
            background: dialogImageUrl2
              ? 'url(' + dialogImageUrl2 + ') no-repeat'
              : bgColorGradient,
            backgroundSize: '100% 100%',
          }"
        >
          <div class="logo_area">
            <span class="vip_logo">
              <img id="js_logo_url_preview" :src="dialogImageUrl" />
            </span>
            <span
              id="js_brand_name_preview"
              style="position: absolute;top: 20px;left: 70px;"
            >
              {{ ruleForm.tim_name
              }}<!--TODO 获取当前登录用户的商户名 或 商盟时，选择一个商户--> </span
            ><br />
            <p
              id="js_title_preview"
              class="card_name"
              style="position: absolute;top: 30px;left: 70px;"
            >
              {{ ruleForm.tim_title }}
            </p>
            <a href="javascript:;"
              ><span class="qrcode" style="display: block"></span
            ></a>
            <span class="dis_card_code">
              <el-image
                style="width: 40px; height: 40px"
                :src="ruleForm.qrcode_url"
                fit="cover"
              ></el-image>
            </span>
          </div>
        </div>
        <div class="things">
          <span>剩余次数：0{{ ruleForm.tim_unit }}</span>
        </div>
        <div v-if="ruleForm.tim_type == 'CODE_TYPE_BARCODE'" class="discode">
          <el-image
            style="width: 250px; height: 60px"
            :src="ruleForm.barcode_url"
            fit="cover"
          ></el-image>
          <span style="margin-top: 20px; margin-bottom: 20px"
            >888 8888 8888 8888</span
          >
        </div>
        <div
          v-else-if="ruleForm.tim_type == 'CODE_TYPE_QRCODE'"
          class="discode"
        >
          <el-image
            style="width: 100px; height: 100px"
            :src="ruleForm.qrcode_url"
            fit="cover"
          ></el-image>
          <span style="margin-top: 10px; margin-bottom: 10px"></span>
        </div>
        <div
          class="things"
          style="
            border: 1px solid #63b359;
            border-radius: 5px;
            width: 150px;
            margin-top: 10px;
          "
        >
          <span style="color: #63b359">{{ ruleForm.tim_button }}</span>
        </div>
        <div class="things" style="margin-top: 10px">
          <span style="font-size: 10px">{{ ruleForm.tim_button_tips }}</span>
        </div>
        <div class="others">
          <div class="card_infos">
            <span>充值续费</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div class="card_infos">
            <span>使用记录</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
          <!--<div class="card_infos">
            <span>
              {{ruleForm.is_custom_one === '1' || !ruleForm.entrance_name_one ? '自定义入口一(选填)' : ruleForm.entrance_name_one}}
            </span>
            <span class="card_right">
              {{ruleForm.is_custom_one === '1' ? '' : ruleForm.guidance_one}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
          <div class="card_infos">
            <span>
              {{ruleForm.is_custom_two === '1' || !ruleForm.entrance_name_two ? '自定义入口二(选填)' : ruleForm.entrance_name_two}}
            </span>
              <span class="card_right">
              {{ruleForm.is_custom_two === '1' ? '' : ruleForm.guidance_two}}&nbsp;&nbsp;&nbsp;
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>-->
          <!--计次卡和代金券没有自定义入口 20221129 宁工-->
          <div class="card_infos">
            <span>会员卡详情</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div class="card_infos">
            <span>试用门店</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div class="card_infos">
            <span>公众号</span>
            <span class="card_right"><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 410px;">
      <div
        class="grid-content bg-purple-light vip-form"
        style="padding-top: 10px"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div>
            <h3>基础设置</h3>
            <el-divider></el-divider>
            <el-form-item label="商户：" prop="shanghu">
              <el-select v-model="ruleForm.meId" :disabled="!isAdd || shDisabled" placeholder="请选择商户">
                <el-option
                        v-for="item in merchants"
                        :key="item.meId"
                        :label="item.meName"
                        :value="item.meId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="次卡名称：" prop="tim_name">
              <el-input
                v-model="ruleForm.tim_name"
                placeholder="请输入次卡名称"
                maxlength="12"
                show-word-limit
                class="createTimeInputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item label="副标题：" prop="tim_title">
              <el-input
                v-model="ruleForm.tim_title"
                placeholder="卡副标题文案，例如：微信次卡"
                maxlength="9"
                show-word-limit
                class="createTimeInputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item label="品牌logo：" prop="tim_logo">
              <!-- auto-upload为是否自动加载；action为图片要上传到的地址，这里随便填一个，因为暂时不用 -->
              <!-- class为动态样式选择，是实现上传图片后上传框消失的关键 -->
              <el-upload
                action="/api/common/upload"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :auto-upload="false"
                :on-change="handleChange"
                :class="objClass"
                :file-list="fileList"
                :limit="1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color: #999; font-size: 12px">
                尺寸120px*120px，支持jpg、jpeg、png图片，文件小于1M
              </span>
              <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="次卡背景：" prop="tim_back_img">
              <!-- auto-upload为是否自动加载；action为图片要上传到的地址，这里随便填一个，因为暂时不用 -->
              <!-- class为动态样式选择，是实现上传图片后上传框消失的关键 -->
              <el-upload
                action="#"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview2"
                :on-remove="handleRemove2"
                :auto-upload="false"
                :on-change="handleChange2"
                :class="objClass2"
                :file-list="fileList2"
                :limit="1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color: #999; font-size: 12px"
                ><!--TODO 二选一校验-->
                次卡背景和颜色二选一，建议尺寸1080px*675px，选择与卡内容相关的图片，文件需要小于2M，支持jpg、jpeg、png图片
              </span>
              <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
              <el-dialog :visible.sync="dialogVisible2">
                <img width="100%" :src="dialogImageUrl2" alt="" />
              </el-dialog>
            </el-form-item>
            <el-form-item label="次卡颜色：" prop="tim_color"
              ><!--TODO 只支持一些特定的颜色，所以这个不是随便选-->
              <!--<el-color-picker
                      v-model="ruleForm.tim_color"
                      :show-alpha="false"
                      @change="bgColorChange"
                      :predefine="predefineColors">
              </el-color-picker>-->
              <span class="select-color-radio">
                <el-radio-group
                  v-model="ruleForm.tim_color"
                  @change="bgColorChange"
                >
                  <el-radio class="color010" label="Color010"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color020" label="Color020"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color030" label="Color030"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color040" label="Color040"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color050" label="Color050"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color060" label="Color060"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color070" label="Color070"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color080" label="Color080"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color081" label="Color081"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color082" label="Color082"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color090" label="Color090"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color100" label="Color100"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color101" label="Color101"
                    ><span></span
                  ></el-radio>
                  <el-radio class="color102" label="Color102"
                    ><span></span
                  ></el-radio>
                </el-radio-group>
              </span>
            </el-form-item>
            <el-form-item label="次卡单位：" prop="tim_unit">
              <el-input
                maxlength="1"
                v-model="ruleForm.tim_unit"
                style="width: 150px"
              />
            </el-form-item>
            <el-form-item label="按钮文案：" prop="tim_button">
              <el-input
                v-model="ruleForm.tim_button"
                class="createTimeInputWidth"
              />
            </el-form-item>
            <el-form-item label="按钮提示：" prop="tim_button_tips">
              <el-input
                v-model="ruleForm.tim_button_tips"
                class="createTimeInputWidth"
              />
            </el-form-item>
            <el-form-item label="条码展示：" prop="tim_type">
              <div class="radio_type_group">
                <div class="radio-ctrl">
                  <span class="radio-s"
                    ><el-radio
                      v-model="ruleForm.tim_type"
                      label="CODE_TYPE_ONLY_BARCODE"
                      >条形码</el-radio
                    ></span
                  >
                  <el-image
                    style="width: 60px; height: 50px"
                    :src="ruleForm.barcode_url"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                    ><el-radio
                      v-model="ruleForm.tim_type"
                      label="CODE_TYPE_ONLY_QRCODE"
                      >二维码</el-radio
                    ></span
                  >
                  <el-image
                    style="width: 50px; height: 50px; margin-left: 15px"
                    :src="ruleForm.qrcode_url"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                    ><el-radio
                      v-model="ruleForm.tim_type"
                      label="CODE_TYPE_BARCODE"
                      >卡面条码</el-radio
                    ></span
                  >
                  <el-image
                    style="width: 60px; height: 50px; margin-left: 20px"
                    :src="ruleForm.barcode_url"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                    ><el-radio
                      v-model="ruleForm.tim_type"
                      label="CODE_TYPE_QRCODE"
                      >卡面二维码</el-radio
                    ></span
                  >
                  <el-image
                    style="width: 50px; height: 50px; margin-left: 25px"
                    :src="ruleForm.qrcode_url"
                    fit="cover"
                  ></el-image>
                </div>
                <div class="radio-ctrl">
                  <span class="radio-s"
                    ><el-radio
                      v-model="ruleForm.tim_type"
                      label="CODE_TYPE_NONE"
                      >不展示</el-radio
                    ></span
                  >
                </div>
              </div>
              <!-- <el-radio-group v-model="ruleForm.tim_type">
                <el-radio label="CODE_TYPE_ONLY_BARCODE">条形码</el-radio>
                <el-radio label="CODE_TYPE_ONLY_QRCODE">二维码</el-radio>
                <el-radio label="CODE_TYPE_BARCODE">卡面条码</el-radio>
                <el-radio label="CODE_TYPE_QRCODE">卡面二维码</el-radio>
                <el-radio label="CODE_TYPE_TEXT">文本</el-radio>
                <el-radio label="CODE_TYPE_NONE">不显示任何码型</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </div>
          <div>
            <h3>次卡规则</h3>
            <el-divider></el-divider>
            <el-form-item label="设置库存：" prop="tim_repertory"
              ><!--对应init_bonus-->
              <el-input-number
                v-model="ruleForm.tim_repertory"
                :min="1"
                :max="9999999999"
                label="设置库存次卡张数"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              :label="'次卡规格' + (index + 1) + '：'"
              v-for="(item, index) in ruleForm.time_specification"
              :key="index"
              required
            >
              <el-col :span="5">
                <el-form-item
                  :prop="'time_specification.' + index + '.original_cost'"
                  :rules="{
                    required: true,
                    message: '请输入原价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    v-model="item.original_cost"
                    placeholder="输入原价"
                  >
                    <template slot="prepend">原价</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5">&nbsp;&nbsp;</el-col>
              <el-col :span="5">
                <el-form-item
                  :prop="'time_specification.' + index + '.selling_price'"
                  :rules="{
                    required: true,
                    message: '请输入售价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    v-model="item.selling_price"
                    placeholder="输入售价"
                  >
                    <template slot="prepend">售价</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="0.5">&nbsp;&nbsp;</el-col>
              <el-col :span="5">
                <el-form-item
                  :prop="'time_specification.' + index + '.timer'"
                  :rules="{
                    required: true,
                    message: '请输入次数',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    v-model="item.timer"
                    placeholder="输入次数"
                  >
                    <template slot="prepend">次数</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="0.5">&nbsp;&nbsp;</el-col>
              <el-col :span="5">
                <el-button
                  v-if="index !== 0"
                  type="danger"
                  size="medium"
                  icon="el-icon-delete"
                  @click.prevent="removeDomain(item)"
                  >删除</el-button
                >
                <el-button
                  v-if="index === 0"
                  @click="addDomain"
                  icon="el-icon-plus"
                  >添加规格</el-button
                >
              </el-col> -->
            </el-form-item>
            <el-row style="margin-bottom: 0">
              <el-col :span="2">
                <el-form-item label="购买限制：" prop="tim_impose">
                  <el-switch v-model="ruleForm.tim_impose"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label=""
                  prop="tim_impose_number"
                  v-if="ruleForm.tim_impose"
                >
                  <el-input
                    v-model="ruleForm.tim_impose_number"
                    οnkeyup="value=value.replace(/[^\d]/g,'')"
                  >
                    <template slot="prepend">最多购买</template>
                    <template slot="append">张</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="支付方式：" prop="tim_pay_type">
              <el-checkbox-group v-model="ruleForm.tim_pay_type">
                <el-checkbox label="1" disabled>微信支付</el-checkbox>
                <el-checkbox label="2" disabled>会员支付</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-row style="margin-bottom: 0">
              <el-col :span="6">
                <el-form-item label="有效日期：" prop="tim_valid_date_type">
                  <el-select
                    v-model="ruleForm.tim_valid_date_type"
                    placeholder="请选择"
                  >
                    <el-option label="相对时间" value="0"></el-option>
                    <el-option label="指定时间" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="ruleForm.tim_valid_date_type === '0'">
                <el-form-item prop="tim_valid_date_day">
                  <el-input
                    v-model="ruleForm.tim_valid_date_day"
                    οnkeyup="value=value.replace(/[^\d]/g,'')"
                  >
                    <template slot="prepend">领取后</template>
                    <template slot="append">天内有效</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="9"
                v-else-if="ruleForm.tim_valid_date_type === '1'"
              >
                <el-form-item prop="tim_valid_date_range">
                  <el-date-picker
                    v-model="ruleForm.tim_valid_date_range"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <!--TODO 适用门店选择 是否需要-->
          </div>
          <!--TODO 分享文案是否必须-->
          <!--<div style="margin-top: 50px;">
            <h3>分享文案</h3>
            <el-divider></el-divider>
            <el-form-item label="分享标题：" prop="tim_share_title">
              <el-input placeholder="请输入分享标题" maxlength="16"
                        show-word-limit :v-model="ruleForm.tim_share_title" class="createTimeInputWidth" />
            </el-form-item>
            <el-form-item label="分享描述：" prop="tim_share_content">
              <el-input placeholder="请输入分享描述" type="textarea" maxlength="1024"
                        show-word-limit :autosize="{ minRows: 5, maxRows: 10 }" v-model="ruleForm.tim_share_content" class="createTimeInputWidth" />
            </el-form-item>
          </div>-->

          <div style="margin-top: 50px">
            <h3>次卡详情</h3>
            <el-divider></el-divider>
            <el-form-item label="特权说明：" prop="tim_in_part"
              ><!--对应prerogative-->
              <el-input
                placeholder="请输入会员卡特权说明"
                type="textarea"
                maxlength="1024"
                show-word-limit
                :autosize="{ minRows: 5, maxRows: 10 }"
                v-model="ruleForm.tim_in_part"
                class="createTimeInputWidth"
              />
            </el-form-item>
            <el-form-item label="操作提示：" prop="cou_reminder"
              ><!--对应prerogative-->
              <el-input
                placeholder="请输入操作提示"
                maxlength="100"
                show-word-limit
                v-model="ruleForm.cou_reminder"
                class="createTimeInputWidth"
              />
            </el-form-item>
            <el-form-item label="使用须知：" prop="tim_use">
              <el-input placeholder="请输入使用须知" v-model="ruleForm.tim_use" class="createTimeInputWidth"
                        maxlength="100"
                        show-word-limit />
            </el-form-item>
          </div>
          <div style="margin-top: 50px">
            <h3>商户介绍</h3>
            <el-divider></el-divider>
            <el-form-item label="客服电话：" prop="tim_phone"
              ><!--对应 service_phone-->
              <el-input placeholder="请输入客服电话" v-model="ruleForm.tim_phone" class="createTimeInputWidth"
                        maxlength="20" />
            </el-form-item>
            
          </div>
          <el-form-item label="适用门店："><!--未保存至数据库-->
            <el-radio-group v-model="typeStore">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">选择门店</el-radio>
            </el-radio-group>
            <el-form-item v-if="typeStore === 1" style="padding-left: 12%" label="">
              <el-button
                type="text"
                @click="choiceStore"
                size="medium"
                >已选择{{this.checkList.length}}门店</el-button>
            </el-form-item>
          </el-form-item>
          <!--<div style="margin-top: 30px">
            <h3>自定义入口一</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_one">
              <el-radio-group v-model="ruleForm.is_custom_one">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_one === '2'">
              <el-form-item label="入口名称" prop="entrance_name_one">
                <el-input  class="createTimeInputWidth" placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_one" />
              </el-form-item>
              <el-form-item  label="自定义链接" prop="entrance_link_one">
                <el-input class="createTimeInputWidth" placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_one" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_one">
                <el-input  class="createTimeInputWidth" placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_one" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_one === '3'">
              <el-form-item label="入口名称" prop="entrance_name_one">
                <el-input  class="createTimeInputWidth" placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_one" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_one">
                <el-input  class="createTimeInputWidth" placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_one" />
              </el-form-item>
              <el-form-item label="小程序user_name" prop="program_id_one">
                <el-input  class="createTimeInputWidth" placeholder="小程序user_name" maxlength="100" v-model="ruleForm.program_id_one" />
              </el-form-item>
              <el-form-item label="小程序页面路径" prop="program_page_one">
                <el-input  class="createTimeInputWidth" placeholder="小程序页面路径" maxlength="100" v-model="ruleForm.program_page_one" />
              </el-form-item>

            </div>
          </div>
          <div style="margin-top: 30px">
            <h3>自定义入口二</h3>
            <el-divider></el-divider>
            <el-form-item label="" prop="is_custom_two">
              <el-radio-group v-model="ruleForm.is_custom_two">
                <el-radio label="1">未设置</el-radio>
                <el-radio label="2">自定义链接</el-radio>
                <el-radio label="3">自定义小程序</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="ruleForm.is_custom_two === '2'">
              <el-form-item label="入口名称" prop="entrance_name_two">
                <el-input  class="createTimeInputWidth" placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_two" />
              </el-form-item>
              <el-form-item  label="自定义链接" prop="entrance_link_two">
                <el-input class="createTimeInputWidth" placeholder="请输入自定义链接" maxlength="128"
                          show-word-limit v-model="ruleForm.entrance_link_two" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_two">
                <el-input  class="createTimeInputWidth" placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_two" />
              </el-form-item>
            </div>
            <div v-if="ruleForm.is_custom_two === '3'">
              <el-form-item label="入口名称" prop="entrance_name_two">
                <el-input  class="createTimeInputWidth" placeholder="请输入入口名称" maxlength="5"
                          show-word-limit v-model="ruleForm.entrance_name_two" />
              </el-form-item>
              <el-form-item label="引导语" prop="guidance_two">
                <el-input  class="createTimeInputWidth" placeholder="请输入引导语" maxlength="6"
                          show-word-limit v-model="ruleForm.guidance_two" />
              </el-form-item>
              <el-form-item label="小程序user_name" prop="program_id_two">
                <el-input  class="createTimeInputWidth" placeholder="小程序user_name" maxlength="100" v-model="ruleForm.program_id_two" />
              </el-form-item>
              <el-form-item label="小程序页面路径" prop="program_page_two">
                <el-input  class="createTimeInputWidth" placeholder="小程序页面路径" maxlength="100" v-model="ruleForm.program_page_two" />
              </el-form-item>

            </div>
          </div>-->
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')"
              >保存</el-button
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button
              @click="
                () => {
                  this.$router.go(-1);
                }
              "
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!--适用门店对话框-->
      <el-dialog
        title="使用门店"
        :visible.sync="dialogUseStore"
        width="700px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div style="height: 15px;padding-left: 10px">
          共<span style="color: red">{{this.quantity}}</span>家门店 &nbsp;&nbsp;&nbsp;&nbsp;
          已选择&nbsp;<span style="color: red">{{checkedAll ? total : checkList.length}}</span>&nbsp;家门店
        </div>
        <el-table
          ref="multipleTables"
          :data="addData"
          tooltip-effect="dark"
          border
          style="width: 100%"
          stripe
          size="small"
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '1px 0' }"
          @select="selectItem"
          @select-all="selectAll"
        >
        <el-table-column type="selection" width="75"  align="center"></el-table-column>
          <el-table-column
            prop="soeName"
            label="门店名称"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="soeAddr"
            label="门店地址"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="storeSubmit"
            >确认</el-button
          >
        </div>
      </el-dialog>
    </div>
  </el-row>
</template>

<script>
import api from "../../api/timeCard";
import config from "../../config/index";
import userApi from "../../api/user";
import merc from "../../api/merchant";
import giftCard from "../../api/giftCard";
export default {
  name: "createTimeCard",
  data() {
    return {
      shDisabled: true,
      couBatch:this.$route.query.couBatch,
      cardMarginTop: {},
      isAdd: true,
      isLoading: false,
      dialogVisible: false,
      fileList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl: "", //图片回显
      dialogVisible2: false,
      fileList2: [],
      objClass2: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl2: "",
      ruleForm: {
        couId: "",
        timId: "",
        shanghu: "小睿智洗",
        tim_name: "",
        tim_title: "",
        tim_logo: null,
        tim_back_img: "",
        tim_color: "Color010",
        tim_type: "",
        tim_in_part: "",
        cou_reminder: "使用时主动出示次卡，方便核销",
        symd: "Y",
        tim_repertory: "",
        tim_impose: false,
        tim_impose_number: "",
        tim_pay_type: [],
        tim_valid_date_type: "0",
        tim_valid_date_day: "30",
        tim_valid_date_range: [],
        tim_share_title: "",
        tim_share_content: "点击查看次卡，成为次卡会员享受更多服务！",
        time_specification: [
          { original_cost: "", selling_price: "", timer: "" },
        ],
        tim_use: "先领取再使用",
        tim_phone: "",
        tim_unit: "次",
        tim_button: "立即使用",
        tim_button_tips: "点击生成核销码",
        barcode_url: require("@/assets/code_type2.jpg"),
        qrcode_url: require("@/assets/code_type1.jpg"),
        meId: '',
      },
      merchants: [],
      rules: {
        tim_name: [
          { required: true, message: "请输入次卡名称", trigger: "blur" },
        ],
        tim_title: [
          { required: true, message: "请输入次卡副标题", trigger: "blur" },
        ],
        tim_logo: [
          { required: true, message: "请选择品牌logo图标", trigger: "change" },
        ],
        tim_type: [
          { required: true, message: "请选择展示类型", trigger: "change" },
        ],
        tim_repertory: [
          { required: true, message: "请输入库存次卡张数", trigger: "change" },
        ],
        original_cost: [
          { required: true, message: "请输入原价", trigger: "change" },
        ],
        selling_price: [
          { required: true, message: "请输入售价", trigger: "change" },
        ],
        timer: [
          { required: true, message: "请输入次数", trigger: "change" },
        ],
        tim_impose_number: [
          {
            required: true,
            message: "请输入最多购买的该次卡张数",
            trigger: "change",
          },
        ],
        tim_impose: [
          { required: true, message: "请选择是否限制", trigger: "change" },
        ],
        // tim_pay_type: [
        //   { required: true, message: "请选择支付方式", trigger: "change" },
        // ],
        tim_valid_date_type: [
          { required: true, message: "请选择有效日期", trigger: "change" },
        ],
        tim_valid_date_day: [
          { required: true, message: "请输入有效期限", trigger: "change" },
        ],
        tim_valid_date_range: [
          { required: true, message: "请选择有效日期区间", trigger: "change" },
        ],
        tim_in_part: [
          { required: true, message: "请输入特权说明", trigger: "change" },
        ],
        cou_reminder: [
          { required: true, message: "请输入操作提示", trigger: "change" },
        ],
        tim_use: [
          { required: true, message: "请输入使用须知", trigger: "change" },
        ],
        tim_phone: [
          { required: false, message: "请输入客服电话", trigger: "blur" },
          {
            pattern: /^(\(\d{3,4}-)|(\d{3.4}-)?\d{3,8}$/,
            message: '请输入正确的电话号码',
          }
        ],
        tim_unit: [
          { required: true, message: "请输入次卡单位", trigger: "change" },
        ],
        tim_button: [
          { required: true, message: "请输入按钮文字", trigger: "change" },
        ],
        entrance_name_one:[
          { required: true, message: "请输入入口名称", trigger: "change" },
        ],
        entrance_link_one:[
          { required: true, message: "请输入自定义链接", trigger: "change" },
        ],
        program_id_one:[
          { required: true, message: "请输入小程序user_name", trigger: "change" },
        ],
        program_page_one:[
          { required: true, message: "请输入小程序页面路径", trigger: "change" },
        ],
        entrance_name_two:[
          { required: true, message: "请输入入口名称", trigger: "change" },
        ],
        entrance_link_two:[
          { required: true, message: "请输入自定义链接", trigger: "change" },
        ],
        program_id_two:[
          { required: true, message: "请输入小程序user_name", trigger: "change" },
        ],
        program_page_two:[
          { required: true, message: "请输入小程序页面路径", trigger: "change" },
        ]
      },
      predefineColors: [
        "#63b359",
        "#2c9f67",
        "#509fc9",
        "#5885cf",
        "#9062c0",
        "#d09a45",
        "#e4b138",
        "#ee903c",
        "#a9d92d",
        "#dd6549",
        "#cc463d",
      ],
      bgColorGradient: "#63b359",
      dialogUseStore:false,//适用门店填出框
      addData:[],
      checkList:[],
      quantity:'',
      typeStore:0,
      checkedAll: false,
      total:'',
      soeId:'',
    };
  },
  created() {
    // this.getUserInfo();
    this.useStore();
    this.getMerchants();
    giftCard.getUstoreStore(this.couBatch).then(res =>{
      this.checkList = res.data.data.result
    })
    if (this.$route.query.couId) {
      this.isAdd = false;
      this.edit();
    }
  },
  watch: {
    $route(to, from) {
      //监听地址栏变动，否则 点了修改，又点了菜单会出问题
      if (to.path === "/timeCard/createTimeCard") {
        //从修改变为了新增
        this.isAdd = true;
        this.$refs["ruleForm"].resetFields();
      }
    },
  },
  mounted() {
    // addEventListener 方法用于向指定元素添加事件
    // 参数1：必选 事件名 例如： click , scroll , mouseenter
    // 参数2：必选 指定事件触发时的执行函数
    // 参数3：可选 布尔值 指定事件是否咋在捕获或冒泡阶段执行
    // true - 事件在句柄捕获阶段执行
    // false - 事件句柄冒泡阶段执行
    window.addEventListener("scroll", this.scrollDs, true);
  },
  methods: {
    getMerchants(){
      merc.getmerchantsByAuth().then(res=>{
        this.merchants = res.data.data.merchants;
        if(res.data.data.merchants && res.data.data.merchants.length === 1) {
          this.ruleForm.meId = res.data.data.merchants[0].meId;
          this.shDisabled = true;
        } else if(res.data.data.merchants && res.data.data.merchants.length > 1) {
          this.shDisabled = false;
        }
      })
    },
    // getUserInfo() {
    //   userApi.userinfo().then((res) => {
    //     this.ruleForm.tim_phone = res.data.data.result.phone;
    //   });
    // },
    edit() {
      this.isLoading = true;
      api.getInfoByCouId(this.$route.query.couId).then((res) => {
        this.ruleForm.couId = this.$route.query.couId;
        this.ruleForm.timId = this.$route.query.timId;
        let timInfo = res.data.data.result;
        console.log(timInfo);
        this.typeStore = timInfo.cou_soe_type;
        this.ruleForm.tim_name = timInfo.cou_title;
        this.ruleForm.tim_title = timInfo.cou_subhead;
        if (timInfo.cou_logo) {
          let timLogoUrl = config.baseUrl + "/" + timInfo.cou_logo;
          this.fileList = [{ url: timLogoUrl }];
          this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
          this.objClass.upLoadShow = false;
          this.dialogImageUrl = timLogoUrl;
          this.ruleForm.tim_logo = "noEdit";
        }
        if (timInfo.tim_back_img) {
          let timBackImgUrl = config.baseUrl + "/" + timInfo.tim_back_img;
          this.fileList2 = [{ url: timBackImgUrl }];
          this.objClass2.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
          this.objClass2.upLoadShow = false;
          this.dialogImageUrl2 = timBackImgUrl;
          this.ruleForm.tim_back_img = "noEdit";
        }
        if (timInfo.cou_color) {
          this.ruleForm.tim_color = timInfo.cou_color;
          this.bgColorChange(timInfo.cou_color);
        }
        this.ruleForm.tim_type = timInfo.tim_type;
        this.ruleForm.tim_in_part = timInfo.cou_desc;
        this.ruleForm.cou_reminder = timInfo.cou_reminder;
        this.ruleForm.tim_repertory = timInfo.cou_count;
        this.ruleForm.tim_impose = timInfo.tim_impose === "true" ? true : false;
        this.ruleForm.tim_impose_number = timInfo.tim_impose_number;
        this.ruleForm.tim_unit=timInfo.tim_unit;
        // this.ruleForm.tim_pay_type = timInfo.tim_pay_type
        //   ? timInfo.tim_pay_type.split(",")
        //   : [];
        this.ruleForm.tim_valid_date_type = timInfo.cou_valid_date_type;
        this.ruleForm.tim_valid_date_day = timInfo.cou_valid_date_day;
        this.ruleForm.tim_valid_date_range = timInfo.cou_valid_date_range
          ? timInfo.cou_valid_date_range.split(",")
          : [];
        //this.ruleForm.tim_share_title = timInfo.timShareTitle;
        //this.ruleForm.tim_share_content = timInfo.timShareContent;
        this.ruleForm.tim_use = timInfo.cou_know;
        this.ruleForm.tim_phone = timInfo.tim_phone;
        this.ruleForm.meId = timInfo.cou_me_id;
       
        let timeSpecification = [];
        if (timInfo.time_specification1) {
          let timeSpecificationOne = timInfo.time_specification1.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        if (timInfo.time_specification2) {
          let timeSpecificationOne = timInfo.time_specification2.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        if (timInfo.time_specification3) {
          let timeSpecificationOne = timInfo.time_specification3.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        if (timInfo.time_specification4) {
          let timeSpecificationOne = timInfo.time_specification4.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        if (timInfo.time_specification5) {
          let timeSpecificationOne = timInfo.time_specification5.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        if (timInfo.time_specification6) {
          let timeSpecificationOne = timInfo.time_specification6.split(",");
          timeSpecification.push({
            original_cost: timeSpecificationOne[0],
            selling_price: timeSpecificationOne[1],
            timer: timeSpecificationOne[2],
          });
        }
        this.ruleForm.time_specification = timeSpecification;
        this.isLoading = false;
      });
    },
    getFileNameByPath(filePath) {
      //根据文件全路径截取文件名
      const spliceLength = filePath.lastIndexOf("/");
      let fileName = filePath.slice(spliceLength + 1);
      return fileName;
    },
    handleChange(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "jpeg";
      const extension3 = testmsg === "png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: "上传的图片只能是.jpg,.jpeg,.png格式!",
          type: "warning",
        });
        this.fileList = [];
      } else if (!isLt1M) {
        this.$message({
          message: "上传的图片大小不能超过1M!",
          type: "warning",
        });
        this.fileList = [];
      } else {
        this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
        this.objClass.upLoadShow = false;
        this.dialogImageUrl = file.url;
        this.ruleForm.tim_logo = file.raw;
      }
    },
    handleRemove(file, fileList) {
      this.objClass.upLoadShow = true; //删除图片后显示上传框
      this.objClass.upLoadHide = false;
      this.dialogImageUrl = "";
      this.ruleForm.tim_logo = null;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "jpeg";
      const extension3 = testmsg === "png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: "上传的图片只能是.jpg,.jpeg,.png格式!",
          type: "warning",
        });
        this.fileList2 = [];
      } else if (!isLt2M) {
        this.$message({
          message: "上传的图片大小不能超过2M!",
          type: "warning",
        });
        this.fileList2 = [];
      } else {
        this.objClass2.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
        this.objClass2.upLoadShow = false;
        this.dialogImageUrl2 = file.url;
        this.ruleForm.tim_back_img = file.raw;
      }
    },
    handleRemove2(file, fileList) {
      this.objClass2.upLoadShow = true; //删除图片后显示上传框
      this.objClass2.upLoadHide = false;
      this.dialogImageUrl2 = "";
      this.ruleForm.tim_back_img = null;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    bgColorChange(val) {
      let color = "";
      switch (val) {
        case "Color010":
          color = "#63b359";
          break;
        case "Color020":
          color = "#2c9f67";
          break;
        case "Color030":
          color = "#509fc9";
          break;
        case "Color040":
          color = "#5885cf";
          break;
        case "Color050":
          color = "#9062c0";
          break;
        case "Color060":
          color = "#d09a45";
          break;
        case "Color070":
          color = "#e4b138";
          break;
        case "Color080":
          color = "#ee903c";
          break;
        case "Color081":
          color = "#f08500";
          break;
        case "Color082":
          color = "#a9d92d";
          break;
        case "Color090":
          color = "#dd6549";
          break;
        case "Color100":
          color = "#cc463d";
          break;
        case "Color101":
          color = "#cf3e36";
          break;
        case "Color102":
          color = "#5E6671";
          break;
      }
      this.bgColorGradient =
        "-webkit-linear-gradient(" +
        this.lightenDarkenColor(color, 40) +
        " 0, " +
        color +
        " 100%";
    },
    lightenDarkenColor(col, amt) {
      //获取比col颜色更浅或更深的颜色（通过正负值控制）
      var usePound = false;
      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }
      var num = parseInt(col, 16);
      var r = (num >> 16) + amt;
      if (r > 255) r = 255;
      else if (r < 0) r = 0;
      var b = ((num >> 8) & 0x00ff) + amt;
      if (b > 255) b = 255;
      else if (b < 0) b = 0;
      var g = (num & 0x0000ff) + amt;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("cou_id", this.ruleForm.couId);
          formData.append("tim_id", this.ruleForm.timId);
          formData.append("tim_name", this.ruleForm.tim_name);
          formData.append("tim_title", this.ruleForm.tim_title);
          formData.append("tim_logo", this.ruleForm.tim_logo);
          formData.append("tim_back_img", this.ruleForm.tim_back_img);
          formData.append("tim_color", this.ruleForm.tim_color);
          formData.append("tim_type", this.ruleForm.tim_type);
          formData.append("tim_in_part", this.ruleForm.tim_in_part);
          formData.append("cou_reminder", this.ruleForm.cou_reminder);
          formData.append("symd", this.ruleForm.symd);
          formData.append("tim_repertory", this.ruleForm.tim_repertory);
          formData.append("tim_impose", this.ruleForm.tim_impose);
          formData.append("tim_impose_number", this.ruleForm.tim_impose_number);
          formData.append("tim_pay_type", this.ruleForm.tim_pay_type);
          formData.append("meId", this.ruleForm.meId);
          formData.append("tim_unit",this.ruleForm.tim_unit)
          formData.append(
            "tim_valid_date_type",
            this.ruleForm.tim_valid_date_type
          );
          formData.append(
            "tim_valid_date_day",
            this.ruleForm.tim_valid_date_day
          );
          formData.append(
            "tim_valid_date_range",
            this.ruleForm.tim_valid_date_range
          );
          //formData.append("tim_share_title", this.ruleForm.tim_share_title);
          //formData.append("tim_share_content", this.ruleForm.tim_share_content);
          formData.append(
            "time_specification",
            JSON.stringify(this.ruleForm.time_specification)
          );
          formData.append("tim_use", this.ruleForm.tim_use);
          formData.append("tim_phone", this.ruleForm.tim_phone);
          api
            .changeTimeInfo(this.isAdd ? "add" : "edit", formData,this.typeStore,this.soeId)
            .then((res) => {
              this.isLoading = false;
              if (res.data.data.result === "success") {
                this.$notify({
                  title: "操作成功",
                  message: "",
                  position: "bottom-right",
                  type: "success",
                });
                this.$router.go(-1);
              }else if(res.data.data.result === "error"){
                this.$notify({
                  title: "请选择适用门店",
                  message: "提示信息",
                  position: "bottom-right",
                  type: "error",
                });
              }
            })
            .catch((e) => {
              this.isLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    scrollDs: function (event) {
      // 页面指定了DTD，即指定了DOCTYPE时，使用document.documentElement。
      // 页面没有DTD，即没指定DOCTYPE时，使用document.body。
      let top = event.target.scrollTop;
      if (top >= 40) {
        this.cardMarginTop = {
          marginTop: "-40px",
          transition: "all 400ms",
        };
      } else {
        this.cardMarginTop = {
          transition: "all 400ms",
        };
      }
    },
    removeDomain(item) {
      let index = this.ruleForm.time_specification.indexOf(item);
      if (index !== -1) {
        this.ruleForm.time_specification.splice(index, 1);
      }
    },
    addDomain() {
      if (this.ruleForm.time_specification.length === 6) {
        this.$message.warning("最多添加6个次卡规格!");
        return;
      }
      this.ruleForm.time_specification.push({
        value: "",
        key: Date.now(),
      });
    },
    choiceStore(){
      this.dialogUseStore = true;
      if(this.$route.query.couBatch !== undefined){
        this.UstoreStore(this.couBatch);
      }
    },
    useStore(){
      giftCard.getUseStore().then(res =>{
        this.quantity = res.data.data.result.records.length;
        this.addData = res.data.data.result.records
      })
      //this.toggleSelection();
    },
    handleClose(){
      this.dialogUseStore = false;
      this.UstoreStore(this.couBatch);
      this.useStore();
    },
    storeSubmit(){
      this.dialogUseStore = false;
    },
    async UstoreStore(couBatch){
    await giftCard.getUstoreStore(couBatch).then(res =>{
      this.checkList = res.data.data.result
    })
    this.toggleSelection();
  },
  toggleSelection() {
    this.$nextTick(() => {
      let soeId = [];
      this.checkList.forEach((row1) => {
        for (let i = 0; i < this.addData.length; i++) {
          if (row1.soeId === this.addData[i].soeId) {
            this.$refs.multipleTables.toggleRowSelection(
                    this.addData[i],
                    true
            );
          }
        }
        soeId.push(row1.soeId);
      });
      this.soeId = soeId.join(",");
    });
   
  },
    selectItem(list, row) {
      let selected = list.length && list.indexOf(row) !== -1;
      if (list.length === 0) {
        const ids = this.addData.map((i) => {
          return i.soeId;
        });
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].soeId) > -1) {
            this.checkList.splice(i, 1);
            i--;
          }
        }
      }
      if(!selected) {//取消选中时，把checkList里的那条也删掉
        let index = this.checkList.findIndex((item) => item.soeId === row.soeId);
        if (index > -1) {
          this.checkList.splice(index, 1)
        }
      }
      const arr = [...list, ...this.checkList];
      this.checkList = this.unique(arr, 'soeId');
      let soeId = [];
      this.checkList.forEach((res) => {
        soeId.push(res.soeId);
      });
      this.soeId = soeId.join(",");
    },
    selectAll(selection) {
      if (selection.length === 0) {
        const ids = this.addData.map((i) => {
          return i.soeId;
        });
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].soeId) > -1) {
            this.checkList.splice(i, 1);
            i--;
          }
        }
      }
      const arr = [...selection, ...this.checkList];
      this.checkList = this.unique(arr, 'soeId');
      let soeId = [];
      this.checkList.forEach((res) => {
        soeId.push(res.soeId);
      });
      this.soeId = soeId.join(",");
    },
    unique(arr,key) {//根据某个属性去重
      let map = new Map();
      return arr.filter(item => !map.has(item[key]) && map.set(item[key],1));
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  .pic {
    margin-top: 5px;
    /*background-color: skyblue;*/
    width: 380px;
    height: 200px;
    border-radius: 10px;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .things {
    margin-top: 5px;
    width: 95%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .others {
    /*margin-top: 25px;*/
    width: 95%;
    /*height: 40%;*/
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .card_infos {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #c4cdd6;
    }
  }
}
.card_right {
  color: #80878c;
}
.bg-purple-light {
  background: #e5e9f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
}
.grid-content {
  border-radius: 4px;
  min-height: 85vh;
  height: 85%;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.member_card .logo_area {
  /*width: 305px;*/
  height: 170px;
  margin: 0;
  padding: 18px 20px 12px 20px;
  text-align: left;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 1px 1px 3px #666;
}
.logo_area {
  margin-bottom: -8px;
  line-height: 42px;
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
}
.dis_card_code {
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #edf0f5;
  right: 20px;
  top: 20px;
}
.member_card .vip_logo {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 21px;
  left: 20px;
}
.vip_logo {
  display: block;
  width: 38px;
  height: 38px;
  padding-top: 0;
  margin-right: 7px;
  border-radius: 22px;
  -moz-border-radius: 22px;
  -webkit-border-radius: 22px;
  border: 1px solid #d3d3d3;
}
.vip_logo {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 60px;
  text-transform: uppercase;
}
.vip_logo img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -moz-border-radius: inherit;
  -webkit-border-radius: inherit;
}
.table-bordered {
  border: 1px solid #edf0f5;
  border-spacing: 0;
  border-collapse: collapse;
}
.createTimeInputWidth {
  width: 60%;
}
</style>
<style>
/*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
.vip-form .upLoadHide .el-upload {
  display: none;
}
.select-color-radio .el-radio__inner {
  width: 30px;
  height: 30px;
}
.select-color-radio .color010 .el-radio__inner {
  background-color: #63b359;
}
.select-color-radio .color020 .el-radio__inner {
  background-color: #2c9f67;
}
.select-color-radio .color030 .el-radio__inner {
  background-color: #509fc9;
}
.select-color-radio .color040 .el-radio__inner {
  background-color: #5885cf;
}
.select-color-radio .color050 .el-radio__inner {
  background-color: #9062c0;
}
.select-color-radio .color060 .el-radio__inner {
  background-color: #d09a45;
}
.select-color-radio .color070 .el-radio__inner {
  background-color: #e4b138;
}
.select-color-radio .color080 .el-radio__inner {
  background-color: #ee903c;
}
.select-color-radio .color081 .el-radio__inner {
  background-color: #f08500;
}
.select-color-radio .color082 .el-radio__inner {
  background-color: #a9d92d;
}
.select-color-radio .color090 .el-radio__inner {
  background-color: #dd6549;
}
.select-color-radio .color100 .el-radio__inner {
  background-color: #cc463d;
}
.select-color-radio .color101 .el-radio__inner {
  background-color: #cf3e36;
}
.select-color-radio .color102 .el-radio__inner {
  background-color: #5e6671;
}
.select-color-radio .el-radio {
  margin-right: 0;
}
.select-color-radio .el-radio,
.el-radio__inner,
.el-radio__input {
  position: inherit; /*去掉选中时中间的白点*/
}
.select-color-radio .is-checked .el-radio__inner {
  background-image: url("../../assets/colorSelectActive.png");
  background-repeat: no-repeat;
  background-size: 94% 94%;
  background-position: center center;
}
.select-color-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #ffffff;
}
</style>
<style scoped>
.radio_type_group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.radio-ctrl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
}
.discode {
  width: 85%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid gray;
}
</style>
